import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Html } from 'cccisd-wysiwyg';
import { client as apollo } from 'cccisd-apollo';
import { OutcomesDataEntry } from 'cccisd-laravel-assignment';

import clientGroupsQuery from './clientGroups.graphql';
import tableQuery from './tableQuery.graphql';

var Fortress = window.cccisd.fortress;

function ProgressData() {
    const [clientGroups, setClientGroups] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    let location = useLocation();

    useEffect(() => {
        initializeData();
    }, [location.hash]);

    async function initializeData() {
        const data = await getClientGroups();
        const formattedClientGroups = formatClientGroups(data.roles.clientList);

        await setClientGroups(formattedClientGroups);

        setIsLoading(false);
    }

    async function getClientGroups() {
        let pawnId = Fortress.user.acting.id;

        const res = await apollo.query({
            query: clientGroupsQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        return res.data;
    }

    function formatClientGroups(roles) {
        return roles.map(({ pawn: { pawnId, pawnHash }, fields: { clientLabel } }) => {
            return {
                groupId: pawnId,
                label: clientLabel,
                pawnId,
                pawnHash,
                flowProps: {},
            };
        });
    }

    return (
        !isLoading && (
            <OutcomesDataEntry
                questprojectIds={[14]}
                groups={clientGroups}
                title="Outcomes Data"
                description={<Html content="Enter progress data gathered during group sessions." />}
                tableColumns={[
                    {
                        name: 'fields.participantId',
                        label: 'Participant Id',
                        sort: true,
                        filter: true,
                    },
                ]}
                tableFields={['participantId']}
                modalTitleField="participantId"
                tableQuery={tableQuery}
            />
        )
    );
}

export default ProgressData;
